import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Comment from './Comment';
import CommentForm from './CommentForm';
import ListItem from './ListItem';
import Targetable from './Targetable';
import { listToTree } from '../utils/wordpressHelper';

import css from './Comments.module.css';

const numberMapping = ['Ein', 'Zwei', 'Drei', 'Vier', 'Fünf', 'Sechs', 'Sieben', 'Acht', 'Neun', 'Zehn', 'Elf', 'Zwölf'];

const propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      author_name: PropTypes.string.isRequired,
      author_url: PropTypes.string.isRequired,
      author: PropTypes.shape({
        wordpress_id: PropTypes.number.isRequired,
      }),
      date: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      wordpress_id: PropTypes.number.isRequired,
      wordpress_parent: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  isClosed: PropTypes.bool.isRequired,
  postId: PropTypes.number.isRequired,
};

const defaultProps = {

};

function Comments({
  comments: flatComments, isClosed, postId,
}) {
  const [replyToCommentId, setReplyToCommentId] = useState(false);
  const nestedComments = listToTree(flatComments, 0);

  return (
    <section className="ft-component ft-grid">
      {flatComments && flatComments.length > 0 && (
        <>
          <h2 className={css.comments__title}>
            <Targetable slug="comments">
              <>
                {numberMapping[flatComments.length - 1]
                  ? numberMapping[flatComments.length - 1]
                  : flatComments.length}
                {flatComments.length > 1 ? ' Kommentare' : ' Kommentar'}
              </>
            </Targetable>
          </h2>
          <ol className={`${css.comments__list} ft-list-reset ft-flex ft-flex--col ft-gap-margin ft-gap-margin--lg ft-gap-margin--col`}>
            {nestedComments.map((comment) => (
              <ListItem
                key={comment.wordpress_id}
                item={comment}
                listClassName={`${css.comments__indentedList} ft-list-reset`}
                listItemClassName="ft-flex ft-flex--col ft-gap-margin ft-gap-margin--lg ft-gap-margin--col"
                content={({
                  content,
                  date,
                  author,
                  wordpress_id: wpId,
                  author_name: authorName,
                  author_url: authorUrl,
                }) => (
                  <>
                    <Comment
                      id={wpId}
                      authorName={authorName}
                      authorUrl={authorUrl}
                      content={content}
                      date={date}
                      byPostAuthor={!!author}
                      setReplyToId={(id) => setReplyToCommentId(id)}
                      isReplyFormOpen={replyToCommentId && replyToCommentId === wpId}
                      isClosed={isClosed}
                    />
                    {!isClosed && replyToCommentId && replyToCommentId === wpId && (
                      <CommentForm
                        replyTo={{ name: authorName, id: wpId }}
                        postId={postId}
                      />
                    )}
                  </>
                )}
              />
            ))}
          </ol>
        </>
      )}
      {!isClosed && !replyToCommentId && (
        <CommentForm postId={postId} />
      )}
      {isClosed && (
        <p className={`${css.comments__closed} ft-font--sm ft-margin-reset`}>Kommentieren ist nicht mehr möglich</p>
      )}
    </section>
  );
}

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export default Comments;
