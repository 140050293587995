import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Button from './Button';
import requestStates from '../utils/requestStates';

import css from './CommentForm.module.css';

const propTypes = {
  replyTo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  postId: PropTypes.number.isRequired,
};

const defaultProps = {
  replyTo: null,
};

function CommentForm({ replyTo, postId }) {
  const hash = typeof window === 'undefined' ? null : window.location.hash;
  const [isCollapsed, setIsCollapsed] = useState(!((hash && hash === '#respond') || replyTo));
  const [requestState, setRequestState] = useState(requestStates.idle);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    site: {
      siteMetadata: {
        wordPressUrl,
        wordPressApiPath,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            wordPressUrl
            wordPressApiPath
          }
        }
      }
    `,
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setRequestState(requestStates.loading);

    const [comment, name, email, website] = event.target.elements;
    const requestData = JSON.stringify({
      author_name: name.value,
      author_email: email.value,
      author_url: website.value,
      content: comment.value,
      parent: replyTo ? replyTo.id : 0,
      post: postId,
    });

    try {
      const response = await fetch(
        `${wordPressUrl}${wordPressApiPath}comments`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: requestData,
        },
      );

      if (response.ok) {
        setRequestState(requestStates.success);
      } else {
        setRequestState(requestStates.error);
        const responseData = await response.json();
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      setRequestState(requestStates.error);
      setErrorMessage(error);
    }
  };

  return (
    <div id="respond" className={`ft-component ${isCollapsed ? css.commentFormIsCollapsed : ''}`}>
      <h2 className={`${css.commentForm__title}${replyTo ? ' ft-visually-hidden' : ''}`}>
        Schreibe einen Kommentar
      </h2>
      {requestState === requestStates.success ? (
        <p className={`${css.commentForm__state} ${css.commentForm__stateSuccess} ft-margin-reset--y`}>
          Vielen Dank für deinen Kommentar!
          {' '}
          Deine Angaben werden nun geprüft und alsbald freigeschaltet.
        </p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className={`${css.commentForm__form} ft-flex ft-flex--col`}
        >
          {requestState === requestStates.error && (
            <div>
              <p className={`${css.commentForm__formItem} ${css.commentForm__state} ${css.commentForm__stateError}`}>
                {errorMessage}
              </p>
            </div>
          )}
          <div className={css.commentForm__collapsibleItem}>
            <p className={`${css.commentForm__formItem} ${css.commentForm__state} ${css.commentForm__stateSm}`}>
              Kommentare werden moderiert, bitte bleib also nett und freundlich.
              {' '}
              <span id="email-notes">Deine E-Mail-Adresse wird nicht veröffentlicht.</span>
              {' '}
              Einfache HTML-Tags sind erlaubt.
            </p>
          </div>
          <div>
            <p className={css.commentForm__formItem}>
              <label htmlFor="comment" className="ft-margin-reset--y">
                <span className="ft-visually-hidden">
                  {replyTo ? `Antwort an ${replyTo.name}` : 'Kommentar'}
                </span>
                <textarea
                  id="comment"
                  placeholder={replyTo ? `Antwort an ${replyTo.name} …` : 'Kommentar …'}
                  cols="45"
                  rows="4"
                  maxLength="65525"
                  required
                  disabled={requestState === requestStates.loading}
                  onFocus={() => setIsCollapsed(false)}
                />
              </label>
            </p>
          </div>
          <div className={css.commentForm__collapsibleItem}>
            <p className={css.commentForm__formItem}>
              <label htmlFor="name" className="ft-margin-reset--y">
                <span className="ft-visually-hidden">Name</span>
                <input
                  id="name"
                  placeholder="Name …"
                  type="text"
                  size="30"
                  maxLength="245"
                  required
                  disabled={requestState === requestStates.loading}
                />
              </label>
            </p>
          </div>
          <div className={css.commentForm__collapsibleItem}>
            <p className={css.commentForm__formItem}>
              <label htmlFor="email" className="ft-margin-reset--y">
                <span className="ft-visually-hidden">E-Mail</span>
                <input
                  id="email"
                  placeholder="E-Mail …"
                  type="email"
                  size="30"
                  maxLength="100"
                  aria-describedby="email-notes"
                  required
                  disabled={requestState === requestStates.loading}
                />
              </label>
            </p>
          </div>
          <div className={css.commentForm__collapsibleItem}>
            <p className={css.commentForm__formItem}>
              <label htmlFor="website" className="ft-margin-reset--y">
                <span className="ft-visually-hidden">Website (optional)</span>
                <input
                  id="website"
                  placeholder="Website (optional) …"
                  type="url"
                  size="30"
                  maxLength="200"
                  disabled={requestState === requestStates.loading}
                />
              </label>
            </p>
          </div>
          <div>
            <p className={css.commentForm__formItem}>
              <Button isSubmit isLoading={requestState === requestStates.loading}>
                Kommentar abschicken
              </Button>
            </p>
          </div>
        </form>
      )}
    </div>
  );
}

CommentForm.propTypes = propTypes;
CommentForm.defaultProps = defaultProps;

export default CommentForm;
