import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import striptags from 'striptags';

import Helmet from '../components/Helmet';
import Layout from '../components/Layout';
import WordPressContent from '../components/WordPressContent';
import WordPressPostContent from '../components/WordPressPostContent';
import DebugGrid from '../components/DebugGrid';
import SlashList from '../components/SlashList';
import Comments from '../components/Comments';
import Divider from '../components/Divider';
import PrismLoader from '../components/PrismLoader';
import { addTrailingSlash } from '../utils/helper';

import css from './Post.module.css';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
        author: PropTypes.shape({
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    wordpressPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      modified: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      commentStatus: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      featuredMedia: PropTypes.shape({
        title: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              presentationWidth: PropTypes.number.isRequired,
            }).isRequired,
            max1920: PropTypes.shape({
              src: PropTypes.string.isRequired,
              presentationHeight: PropTypes.number.isRequired,
              presentationWidth: PropTypes.number.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }),
    }).isRequired,
    allWordpressWpComments: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string.isRequired,
          author_name: PropTypes.string.isRequired,
          author_url: PropTypes.string.isRequired,
          author: PropTypes.shape({
            wordpress_id: PropTypes.number.isRequired,
          }),
          date: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          wordpress_id: PropTypes.number.isRequired,
          wordpress_parent: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    postId: PropTypes.number.isRequired,
  }).isRequired,
};

const defaultProps = {

};

function Post({
  location: {
    pathname,
  },
  data: {
    site: {
      siteMetadata: {
        title: siteTitle,
        siteUrl,
        author,
      },
    },
    wordpressPost: {
      title,
      date,
      modified,
      excerpt,
      content,
      commentStatus,
      categories,
      tags,
      featuredMedia,
    },
    allWordpressWpComments: {
      nodes: comments,
    },
  },
  pageContext: {
    postId,
  },
}) {
  const { 0: firstCategory } = categories;
  const schemata = [
    {
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': addTrailingSlash(`${siteUrl}${pathname}`),
      },
      headline: title,
      description: striptags(excerpt),
      ...(featuredMedia && featuredMedia.localFile && {
        image: {
          '@type': 'ImageObject',
          url: `${siteUrl}${featuredMedia.localFile.childImageSharp.max1920.src}`,
          height: featuredMedia.localFile.childImageSharp.max1920.presentationHeight,
          width: featuredMedia.localFile.childImageSharp.max1920.presentationWidth,
        },
      }),
      author: {
        '@id': '#author',
      },
      datePublished: date,
      dateModified: modified,
      ...(tags && { keywords: tags.map(({ name: tagName }) => tagName).join(', ') }),
      publisher: {
        '@id': '#publisher',
      },
    },
    {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        (firstCategory
          && {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': addTrailingSlash(`${siteUrl}${firstCategory.path}`),
              name: firstCategory.name,
            },
          }
        ),
      ],
    },
  ];

  return (
    <>
      <Helmet
        path={pathname}
        title={title}
        description={striptags(excerpt)}
        type="article"
        created={date}
        updated={modified}
        image={featuredMedia && featuredMedia.localFile ? {
          src: `${siteUrl}${featuredMedia.localFile.childImageSharp.max1920.src}`,
          height: featuredMedia.localFile.childImageSharp.max1920.presentationHeight,
          width: featuredMedia.localFile.childImageSharp.max1920.presentationWidth,
        } : null}
        topLevelSchemata={schemata}
      />
      <PrismLoader />
      <Layout>
        <article className="ft-gap-margin ft-gap-margin--col ft-gap-margin--lg">
          <header className="ft-grid ft-flex ft-flex--col">
            <h1 className={`${css.post__title} ft-margin-reset--y`}>{title}</h1>
            {categories && categories.length > 0 && (
              <div className={`${css.post__categories} ft-font--sm ft-font--bold`}>
                <span className="ft-visually-hidden">Kategorien:</span>
                <SlashList categories={categories.map(({ name, path }) => ({ url: path, label: name }))} linkClassName="ft-text-decoration--none" />
              </div>
            )}
          </header>
          {featuredMedia && featuredMedia.localFile && (
            <div className="ft-grid">
              <figure>
                <Img
                  fluid={featuredMedia.localFile.childImageSharp.fluid}
                  alt={featuredMedia.alt_text}
                  title={featuredMedia.title}
                  style={{
                    maxWidth: featuredMedia.localFile.childImageSharp.fluid.presentationWidth,
                  }}
                />
                {featuredMedia.caption && (
                  <figcaption>
                    <WordPressContent content={featuredMedia.caption} />
                  </figcaption>
                )}
              </figure>
            </div>
          )}
          {content && (
            <div className={`${css.post__content} ft-grid`}>
              <div>
                <WordPressPostContent content={content} />
              </div>
            </div>
          )}
          <footer className={`${css.post__metaFooter} ft-grid ft-font--sm`}>
            <div>
              <Link to={author.path}>{author.name}</Link>
              <> hat diesen Text am </>
              <Link to={pathname} className="ft-text-decoration--none ft-color--inherit">
                <time dateTime={date}>
                  {new Date(date).toLocaleDateString('de-DE', {
                    year: 'numeric', month: 'long', day: 'numeric',
                  })}
                </time>
              </Link>
              {categories && categories.length > 0 && (
                <>
                  {categories.length > 1 ? ' in den Kategorien ' : ' in der Kategorie '}
                  {categories
                    .map(
                      ({ name, path }) => (
                        <Link key={path} to={path}>{name}</Link>
                      ),
                    )
                    .reduce((prev, curr) => [prev, ', ', curr])}
                </>
              )}
              {tags && tags.length > 0 && (
                <>
                  {tags.length > 1 ? ' mit den Schlagworten ' : ' mit dem Schlagwort '}
                  {tags
                    .map(
                      ({ name, path }) => (
                        <Link key={path} to={path}>{name}</Link>
                      ),
                    )
                    .reduce((prev, curr) => [prev, ', ', curr])}
                </>
              )}
              <> veröffentlicht.</>
            </div>
          </footer>
          <Divider />
          <Comments comments={comments} isClosed={commentStatus !== 'open'} postId={postId} />
        </article>
      </Layout>
      <DebugGrid />
    </>
  );
}

export const query = graphql`
  query PostQuery($postId: Int!) {
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
          path
        }
      }
    }
    wordpressPost(
      wordpress_id: { eq: $postId }
      status: { eq: "publish" }
    ) {
      title
      date
      modified
      excerpt
      content
      commentStatus: comment_status
      categories {
        name
        path
      }
      tags {
        name
        path
      }
      featuredMedia: featured_media {
        title
        caption
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
            max1920: fluid(maxWidth: 1920) {
              presentationHeight
              presentationWidth
              src
            }
          }
        }
      }
    }
    allWordpressWpComments(
      filter: {
        post: { eq: $postId }
        status: { eq: "approved" }
      }
      sort: {
        order: ASC
        fields: date
      }
    ) {
      nodes {
        content
        author_name
        author_url
        author {
          wordpress_id
        }
        date
        link
        wordpress_id
        wordpress_parent
      }
    }
  }
`;

Post.propTypes = propTypes;
Post.defaultProps = defaultProps;

export default Post;
