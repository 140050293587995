import React from 'react';
import PropTypes from 'prop-types';

import WordPressContent from './WordPressContent';
import Link from './Link';
import Button from './Button';
import Targetable from './Targetable';

import GullImage from '../images/gull.inline.svg';
import css from './Comment.module.css';

const propTypes = {
  id: PropTypes.number.isRequired,
  authorName: PropTypes.string.isRequired,
  authorUrl: PropTypes.string,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  byPostAuthor: PropTypes.bool,
  setReplyToId: PropTypes.func.isRequired,
  isReplyFormOpen: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

const defaultProps = {
  authorUrl: null,
  byPostAuthor: false,
};

function Comment({
  id,
  authorName,
  authorUrl,
  content,
  date,
  byPostAuthor,
  setReplyToId,
  isReplyFormOpen,
  isClosed,
}) {
  const anchor = `comment-${id}`;
  return (
    <article className={`ft-component ${byPostAuthor ? css.commentByPostAuthor : ''} ft-gap-margin ft-gap-margin--col ft-gap-margin--md`}>
      <footer className="ft-flex ft-flex--col">
        <strong className={css.comment__author}>
          <Targetable slug={anchor}>
            {authorUrl ? (
              <Link to={authorUrl} rel="nofollow">{authorName}</Link>
            ) : (
              <>{authorName}</>
            )}
            {byPostAuthor && (
              <span className={`${css.comment__authorIcon} ft-svg-path-currentColor`}>
                <GullImage />
              </span>
            )}
          </Targetable>
        </strong>
        <span className={`${css.comment__meta} ft-font--sm`}>
          <span>schrieb am </span>
          <Link to={`#${anchor}`} className="ft-text-decoration--none">
            <time dateTime={date}>
              {new Date(date).toLocaleDateString('de-DE', {
                year: 'numeric', month: 'long', day: 'numeric',
              })}
              <> um </>
              {new Date(date).toLocaleTimeString('de-DE', {
                hour: '2-digit', minute: '2-digit',
              })}
              <> Uhr</>
            </time>
          </Link>
        </span>
      </footer>
      {content && (
        <div className={`${css.comment__content} ft-gap-margin ft-gap-margin--col ft-gap-margin--md`}>
          <WordPressContent content={content} />
        </div>
      )}
      {!isClosed && (
        <div className={css.comment__reply}>
          {isReplyFormOpen ? (
            <Button isSmall onClick={() => setReplyToId(null)}>
              {`Antwort an ${authorName} abbrechen`}
            </Button>
          ) : (
            <Button isSmall onClick={() => setReplyToId(id)}>
              {`${authorName} antworten`}
            </Button>
          )}
        </div>
      )}
    </article>
  );
}

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default Comment;
