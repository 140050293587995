import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  item: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  content: PropTypes.func.isRequired,
  orderedList: PropTypes.bool,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
};

const defaultProps = {
  orderedList: true,
  listClassName: null,
  listItemClassName: null,
};

/**
 * Creates deeply nested lists.
 *
 * @see https://stackoverflow.com/questions/53773088/create-nested-jsx-list-items-from-multi-level-nested-array-of-objects-reactjs/53773173#53773173
 */
function ListItem({
  item,
  content,
  orderedList = true,
  listClassName = null,
  listItemClassName = null,
}) {
  let itemChildrenWrapped = null;
  if (item.children && item.children.length > 0) {
    const itemChildren = item.children.map((i) => (
      <ListItem
        key={i.wordpress_id}
        item={i}
        content={content}
        orderedList={orderedList}
        listClassName={listClassName}
        listItemClassName={listItemClassName}
      />
    ));
    itemChildrenWrapped = (
      orderedList
        ? <ol className={listClassName}>{itemChildren}</ol>
        : <ul className={listClassName}>{itemChildren}</ul>
    );
  }

  return (
    <li className={`ft-component ${listItemClassName}`}>
      {content(item)}
      {itemChildrenWrapped}
    </li>
  );
}

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
