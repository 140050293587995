import React from 'react';

function Targetable({ slug, children }) {
  return (
    <div className="ft-targetable" id={slug}>
      <a href={`#${slug}`} className="ft-targetable__action" aria-hidden="true" />
      {children}
    </div>
  );
}

export default Targetable;
